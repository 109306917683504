import Connection from './Connection'
import Cookie from 'js-cookie'

export default {
  getCookie () {
    const token = Cookie.get('XSRF-TOKEN')
    if (token) {
      return new Promise(resolve => { resolve(token) })
    }
    return Connection.get('/sanctum/csrf-cookie')
  }
}
