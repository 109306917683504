import axios from 'axios'

const Connection = axios.create({
  baseURL: 'https://medicert.com.co:8030'
  // baseURL: 'http://localhost:8030'
})

Connection.defaults.withCredentials = true

export default Connection
